import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import graphic3 from "../../images/oxygenLogo-w.svg"
import styled from "styled-components"

const Wrapper = styled.section`
  .grid2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .rings {
    max-width: 600px;
  }
  .screens {
    margin-top: 0;
  }
  .link {
    color: #52aabf;
  }
  picture {
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    .user {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 10px;
    }
    .user p {
      font-size: 12px;
    }
    .users h5 {
      font-size: 0.95rem;
    }
    .persona {
      margin-bottom: 0.5rem;
    }
    .areas {
      grid-template-columns: repeat(1, 1fr);
    }
    .screen {
      margin-bottom: 1rem;
    }
  }
`
const SecondPage = () => (
  <Layout>
    <Seo title="Page two" />
    <Wrapper>
      <div className="project-header">
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-hero.png"
          alt="Project"
          placeholder="dominantColor"
          className="hero"
        />
        <div className="project-title">
          <div className="client">
            <img className="client-logo" alt="logo" src={graphic3} />
            <p>/ experience design</p>
          </div>
          <div className="desc">
            <h2>Learning Platform Design</h2>
            <p>
              designing and prototyping a modern digital learning experience
              platform and product concept
            </p>
          </div>
        </div>
      </div>
      <div className="highlights">
        <h5>Project Scope:</h5>
        <ul className="scope">
          <li>Information Architecture</li>
          <li>Workflow</li>
          <li>Wireframe</li>
          <li>Visual Design</li>
          <li>Prototype</li>
        </ul>
      </div>
      <div className="highlights">
        <h5>Overview:</h5>
        <p>
          Oxygen is a leader in the design and development of modern learning
          experiences. As Creative Director, I lead in the front-end development
          of role-based, transformational learning experiences. The Oxygen
          Learning Platform is a prototype concept aimed at making the new and
          complex simple and consumable. It provides an architectural framework
          for organizing role-based content on a progressive learning journey.
          The platform is designed as a white-label enterprise solution that
          supports extensive customization for wealth of learning use cases.
        </p>
      </div>
      <div className="highlights">
        <h5>Project Goals:</h5>
        <ul>
          <li>Develop a modern, digital learning experience platform</li>
          <li>
            Design a framework that supports structured learning journeys with
            rich interactivity
          </li>
          <li>
            Create a visual design system that can be customized for different
            roles and by different customers
          </li>
          <li>
            Drive new standards for “what good looks like” in digital learning
          </li>
          <li>
            Develop a product prototype that represents the best-in-class
            learning experience
          </li>
        </ul>
      </div>
      <div className="highlights">
        <h5>My Role:</h5>
        <ul>
          <li>
            Facilitate discovery sessions with company leaders on project goals
            and requirements
          </li>
          <li>
            Lead the development of experience design concepts based on
            executive leadership inputs
          </li>
          <li>
            Create scalable, modular design templates and layouts based on
            role-based learning journeys
          </li>
          <li>
            Design a branded learning experience that represents a viable
            product solutions
          </li>
          <li>
            Build a functional InVision prototype for use in sales pursuits
          </li>
        </ul>
      </div>
      <br />
      <div className="screens">
        <div className="project-label">
          <p>Design Executions</p>
        </div>
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-screen-1.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-screen-2.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-screen-3.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-screen-4.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
        <StaticImage
          src="../../images/oxygen-learning/oxygen-learning-screen-5.jpg"
          alt="Project"
          placeholder="dominantColor"
          quality={100}
          className="screen"
        />
      </div>
      <div className="footer-nav">
        <Link to="/">&#x2190; Recent work</Link>
        <span></span>
        <Link to="/work/oxygen">Next Project &#8594; </Link>
      </div>
    </Wrapper>
  </Layout>
)

export default SecondPage
